<template>
    <div>
        <b-sidebar backdrop id="expense_type" width="50rem" aria-labelledby="sidebar-no-header-title" no-header style="direction:ltr" right shadow >
            <template #default="{ hide }">
            <div class="m-1 text-start" style="direction:rtl">
                <div style="width:120px;margin-right:10px;background:#28467c;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                    <span>الحالات</span>
                </div>
                <div @click="hide" id="close_expense_type" style="float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                    <span>اغلاق</span>
                </div>
            </div>
            <v-divider></v-divider>
            <div class="px-3 py-2" style="direction:rtl">
                <v-row>
                    <v-col cols="6">
                        <label>الاسم بالعربي</label>
                        <b-form-input class="inborder"
                            :label="lang.expense_namear + ` *`"
                            v-model="name_ar"
                            />
                    </v-col>
                    <v-col cols="6">
                        <label>الاسم بالانجليزي</label>
                        <b-form-input class="inborder"
                            v-model="name_en"
                            />
                    </v-col>
                    <v-col cols="6">
                        <label>الخلفية</label>
                        <v-color-picker class="inborder"
                            v-model="bgColor"
                            />
                    </v-col>
                    <v-col cols="6">
                        <label>لون الخط</label>
                        <v-color-picker class="inborder"
                            v-model="color"
                            />
                    </v-col>
                </v-row>
            </div>
    </template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                <b-button type="button" variant="success" @click='CreateNew()' class="ma-2" style="width:100px;">{{ lang.save }}</b-button>
                </div>
            </template>
        </b-sidebar>
        <vue-snotify></vue-snotify>
    </div>
</template>

<script>
import {SnotifyPosition, SnotifyStyle} from 'vue-snotify';
import axios from 'axios';
export default {
    data() {
        return {
            name_ar: '',
            name_en: '',
            id: 0,
            bgColor: '#ffffff',
            color: '#000000',
            swatches: [
                ['#FF0000', '#AA0000', '#550000'],
                ['#FFFF00', '#AAAA00', '#555500'],
                ['#00FF00', '#00AA00', '#005500'],
                ['#00FFFF', '#00AAAA', '#005555'],
                ['#0000FF', '#0000AA', '#000055'],
            ],
        }
    },
    computed:{
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
    },
    methods: {
        userfullname(){
            this.user.full_name = this.employees[this.user.empid];
        },
        getEmp(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type","getEmpl");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[all]","all");
            axios.post(
                this.$SAMCOTEC.r_path , post
            )
            .then((res) => {
                // // console.log(res);
                this.employees = res.data.results.data;
            });
        },
        resetValues(item){
            // console.log(item);
            this.name_ar = item.name_ar;
            this.name_en = item.name_en;
            this.id = item.id;
            this.bgColor = item.bgColor;
            this.color = item.color;
        },
        CreateNew(){
            if(this.name_ar == '' || this.name_en == ''){
                let error_msg = this.lang.name_ar_en_required;
                this.$snotify.error(error_msg, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , "addStaticOption");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[phrase]',this.$parent.currentStatus);
            post.append('data[name_ar]',this.name_ar);
            post.append('data[name_en]',this.name_en);
            post.append('data[id]',this.id);
            post.append('data[bgColor]',this.bgColor);
            post.append('data[color]',this.color);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then(() => {
                let message = 'تمت اضافة الحالة بنجاح';
                if(this.id != 0){
                    message = 'تم تعديل الحالة بنجاح';
                }
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                // this.getExpensesTypes();
                document.getElementById('close_expense_type').click();
                // // console.log(this.$parent);
                this.$parent.getExpensesTypes ();
            })
        },
    },
    created() {
       // this.camp.types = {text:'رسائل واتس آب', value:'2'};
    //    this.getEmp()
    },
}
</script>